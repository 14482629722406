'use client';

import Link from 'next/link';

export default function NotFound() {
  return (
    <div className='h-screen w-full flex items-center justify-center'>
      <div className='max-w-lg text-center w-full flex items-center justify-center flex-col space-y-3 border rounded-md p-12'>
        <h2 className='text-3xl font-bold'>404</h2>
        <p className='text-muted-foreground'>
          Esta página no existe o no tienes permisos para acceder a ella
        </p>
        <Link className='font-bold hover:underline' href={'/'}>
          Inicio
        </Link>
      </div>
    </div>
  );
}
